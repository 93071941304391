<template>
	<div class="info_mian">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/' }">当前位置：首页</el-breadcrumb-item>
			<el-breadcrumb-item v-if="info.cTID_NAME" :to="{ path: '/news' }">{{info.cTID_NAME}}</el-breadcrumb-item>
			<el-breadcrumb-item>{{ info.cTITLE }}</el-breadcrumb-item>
		</el-breadcrumb>

		<div class="new_info">
			<div class="title">{{ info.cTITLE }}</div>
			<div class="info">
				信息来自:{{ info.cFROM }} 发布时间：{{stringToDates(info.dREDATE)}}
				有效期至：{{ stringToDates(info.dYXQ) }}
			</div>

			<div v-html="info.cCONTENTS"></div>

			<div class="fujian" v-if="info.appendix && info.appendix.length > 0">
				附件下载：
				<div class="fujian-li">
					<a v-for="(item, index) in info.appendix" :href="defimgurl + item.files"
						:key="index">{{ item.title }}</a>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import utils from "@/assets/js/utils";
	import {
		isnull
	} from "@/assets/js/index";
	import global from "@/assets/js/globalconfig";
	import {
		Getnewsbyid,
		newsview
	} from "@/api/home";
	export default {
		data() {
			return {
				defimgurl: global.baseimgurl,
				title: "adas",
				info: {},
			};
		},
		created() {
			var id = this.$route.query.id;
			if (!isnull(id)) {
				this.Getinfo(id);
			}
		},
		methods: {
			stringToDates(data) {
				return utils.stringToDate(data);
			},
			Getinfo(id) {
				Getnewsbyid({
					id: id
				}).then((res) => {
					if (res.success) {
						if (res.data.appendix) {
							res.data.appendix = JSON.parse(res.data.appendix);
						}
						try{
						res.data.cCONTENTS=decodeURIComponent(res.data.cCONTENTS);
						}catch(e){
							
						}
						this.info = res.data;
						localStorage.setItem("cTID", res.data.cTID);
						this.newsview(id);
					}
				});
			},
			newsview(id) {
				newsview({
					id: id
				}).then((res) => {
					if (res.success) {}
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.info_mian {
		width: 1200px;
		margin: 0 auto;
		padding: 20px 0;

		.new_info {
			background-color: #fff;
			padding: 20px;
			margin-top: 20px;

			.title {
				text-align: center;
				font-size: 24px;
				font-weight: bold;
				color: #0e75aa;
			}

			.info {
				text-align: center;
				margin-top: 10px;
				font-size: 14px;
				color: #666666;
				padding-bottom: 10px;
				margin-bottom: 10px;
				border-bottom: 1px solid #bdbdbd;
			}

			.fujian {
				margin-top: 20px;
				display: flex;
			}

			.fujian-li {
				display: flex;
				align-items: center;
				flex-wrap: wrap;

				a {
					margin-bottom: 10px;
					margin-right: 20px;
				}
			}
		}
	}
</style>
<style>
	table tr {
		border-right: 1px solid #e0e0e0 !important;
		border-bottom: 1px solid #e0e0e0 !important;
		border-top: 1px solid #e0e0e0 !important;
	}

	table td {
		/* flex: 1; */
		padding: 5px;
		font-size: 12px;
		border-left: 1px solid #e0e0e0;
		word-break: break-all;
	}

	table {
		border-collapse: collapse;
	}

	table td {
		empty-cells: show;
	}
</style>
